let mixin = {
  methods: {
    /**
     * 获取商品数据
     */
    getGoodsList(pageNo, override,theme_id)
    {
      let that = this;
      this.post(
        this.API.getGoodsList,
        {pageNo:pageNo,pageSize:20,filter:that.filters,theme_id}
      ).then(response => {
        //console.log(444, response)
        if(response.data) {
          let {current_page, last_page, total, data: goodsList} = response.data.goods
		  console.log(current_page);
		  console.log(last_page);
          that.page = current_page     //当前的页号
          that.totalPage = last_page    //总页数
          that.totalNumber = total //总条数
          that.goodsList = override ? goodsList : that.goodsList.concat(goodsList)
		  that.loading = false;
          //主题包
          if(theme_id>0) {
            that.currentTheme = response.data.currentTheme //主题包
          }

          that.getCartGoodsList();
        }
      })
    },
	/**
	 * 获取商品数据
	 */
	getTourGoodsList(pageNo, override,theme_id)
	{
	  let that = this;
	  this.post(
	    this.API.getTourGoodsList,
	    {pageNo:pageNo,pageSize:20,filter:that.filters,theme_id}
	  ).then(response => {
	    //console.log(444, response)
	    if(response.data) {
	      let {current_page, last_page, total, data: goodsList} = response.data.products
		  console.log(current_page);
		  console.log(last_page);
	      that.page = current_page     //当前的页号
	      that.totalPage = last_page    //总页数
	      that.totalNumber = total //总条数
	      that.goodsList = override ? goodsList : that.goodsList.concat(goodsList)
		  that.loading = false;
	      //主题包
	      if(theme_id>0) {
	        that.currentTheme = response.data.currentTheme //主题包
	      }
	
	      that.getCartGoodsList();
	    }
	  })
	},
    /**
     * 加入购物车
     */
    addCart(e){
      let that = this;
      let gid = e;
      this.post(
        this.API.addCart,
        {gid:gid})
        .then(response => {
          this.skuPopGoodsInfo =response.data.goods
          if(that.skuPopGoodsInfo.have_sale_attr) {
            this.showSkuPop = true
          } else {
            that.addToCartLocal({});
          }
        })
    },
    /**
     * 加入购物车
     */
    addToCartLocal(e){
	  let selectedSkuNumber = 1;
	  if(typeof e.number == "undefined")
	  {
	  	selectedSkuNumber = this.selectedSkuNumber;
	  }else{
	  	selectedSkuNumber = e.number;
	  }

	  let selectedSkuId = '';
	  if(typeof e.sku_id == "undefined")
	  {
	  	selectedSkuId = this.selectedSkuId;
	  }else{
	  	selectedSkuId = e.sku_id;
	  }
      let that = this;
      let goodsInfo = this.skuPopGoodsInfo;
      // let selectedSkuId = this.selectedSkuId;
      // let selectedSkuNumber = this.selectedSkuNumber;
      let group = this.familyInfo;
      this.checkLogin(function(){
        //判断商品是否有开启SKU属性
        if(goodsInfo.have_sale_attr) {
          if(selectedSkuId>0){
            that.addToCart(goodsInfo.goods_id,selectedSkuId,selectedSkuNumber,group,function(res){
              if(res.data.err){
                that.$toast(res.data.msg);
              }else{
                that.$toast(res.data.msg);
                that.refeshCartTotal();
                that.showSkuPop =false

              }
            });
          }else{
            that.showSkuPop = true
          }
        } else {
          if(goodsInfo.sku_id=='0'){
            //如果是SKU类型商品,弹出商品选择框
            this.showSkuPop = true
          }else{
			  console.log("22222");
            //如果非SKU类型商品，直接加入到购物车
            that.addToCart(goodsInfo.goods_id,goodsInfo.sku_id,1,group,function(res){
              console.log(res);
			  if(res.err){
                that.$toast(res.msg);
              }else{
                that.$toast(res.msg);
                that.refeshCartTotal();
				that.getCartAmount();
                that.showSkuPop =false
              }
            });
          }
        }
      });
    },
    /**
     * 从购物车中减去
     */
    reduceToCart:function(e){
      let that = this;
      let gid = e;
      let group = this.familyInfo;
      this.post(
        this.API.addCart,{gid:gid})
        .then(response => {
          let goodsInfo = response.data.goods;
          that.skuPopGoodsInfo = goodsInfo

          if(that.skuPopGoodsInfo.have_sale_attr)
          {
            that.$toast('请在购物车中操作');
          }
          else
          {
            //如果非SKU类型商品，直接加入到购物车
            that.addToCart(goodsInfo.goods_id,goodsInfo.sku_id,'-1',group,function(res){
				console.log(res);
              if(res.err){
                that.$toast(res.msg);
              }else{
                that.$toast(res.msg);
                that.refeshCartTotal();
				that.getCartAmount();
                that.showSkuPop =false
              }
            });
          }
        })
    },
    /**
     * 直接购买
     */
    quickToBuy:function(){
      let skuid = this.selectedSkuId;
      let number = this.selectedSkuNumber;
      let cart = [{sku_id:skuid,number:number}];

      if(skuid==0) {
        this.showSkuPop = true
        return;
      }

      let that = this;
      this.post(
        this.API.quickToBuy,
        {cart})
        .then(response => {
          if(response.data.err=='1')
          {
            this.$toast(response.data.msg);
            return;
          }

          var storehouse_id = response.data.data;

          cart.forEach(function(i,k){
            cart[k]['storehouse_id'] = storehouse_id;
          })

          cart =  JSON.stringify(cart);
          this.$router.push({
            name: 'spjs',
            query: {
              cart,
              quick: 1,
            }
          })
        })
    },
    /**
     * 更新已选择的规格
     */
    updateSelectedSku:function(e){
      this.selectedSkuId = e.sku_id
      this.selectedSkuName = e.sku_name
      this.selectedSkuNumber=e.number
    },
    /**刷新购物车数量 */
    refeshCartTotal:function()
    {
      let that = this;
      this.post(
        this.API.refeshCartTotal,{}
      ).then(response => {
        that.cartGoodsTotal = response.data+""
      })

      that.getCartGoodsList();
    },
    getCartGoodsList:function(){
      //获取购物车内信息
      let that = this;
      this.post(
        this.API.getCartGoodsList,{})
        .then(response => {
          if(response.data) {
            that._loadCartData(response.data);
          }
        })
    },
    //整理数据，标记商品是否已在购物车中，以及购物车中数量
    _loadCartData:function(cartGoodsList){
      let _goodsList = [];
		let that = this;
      this.goodsList.forEach(function(goods,k) {
		  that.$set(goods, 'isInCart', 0)
		  that.$set(goods, 'cart_number', 0)
        cartGoodsList.forEach(function(cartGoods,i){
          if(goods.goods_id==cartGoods.goods_id)
          {
			  that.$set(goods, 'isInCart', 1)
			  that.$set(goods, 'cart_number', cartGoods.goods_number)
          }
        })

        _goodsList.push(goods);
      });

      this.goodsList = _goodsList
	  console.log("装载了购物信息后的商品信息");
	  console.log(this.goodsList);
    },
    //刷新购物车页面数据
    cartGoodsList()
    {
      let that = this;
      let familyInfo = this.familyInfo;
      this.post(
        this.API.getCartGoodsList,
        {group:familyInfo}
      ).then(response => {
        if(response.data) {
          this.cartGoods = response.data

          let _checkedGoodsAmount = 0;
          response.data.forEach(function(goods,k){
            _checkedGoodsAmount = _checkedGoodsAmount + goods.goods_number * goods.order_price;
          })

          that.cartGoodsAmount = _checkedGoodsAmount
        }
      })
    },
	getCartAmount()
	{
	    var that = this;
		this.post(
		  this.API.getCartAmount,{}
		).then(response => {
			console.log(response);
			if(response.data>"0"){
			    that.showBottomBar=true;
			    that.cartAdmount=response.data+"";
			}else{
			    that.showBottomBar=false;
			    that.cartAdmount=0;
			}
		})
	},
	InArray (search,array){
	    for(var i in array){
	        if(array[i]==search){
	            return true;
	        }
	    }
	    return false;
	}
  }
}

export default mixin
