<template>
  <div class="page">
    <!--搜索-->
	<div class="offen-search-top">
	  <van-search
	      @focus="focusSearch"
	      v-model="searchValue"
	      @change="searchGoods"
	      placeholder="搜索商品名称" />
	</div>
    <div v-if="currentTheme.v_img" class="nav theme-nav flexcenter">
	  <img class="full-img navbg" :src="currentTheme.v_img">
    </div>

    <div class="offen-search" @click="hideOffenSearch" v-show="!isOffenHidden" style="top: 48px">
      <!-- <div class="title">热门搜索</div>
      <div class="list">
        <div @click="selHotWord" class="item">水果</div>
      </div> -->
    </div>
	
	<div :class="currentTheme.v_img?'theme-page':'theme-page no-theme-page'" :style="{backgroundColor:currentTheme.v_color}">
		<van-list
		  v-model="loading"
		  :finished="finished"
		  finished-text="没有更多了"
		  @load="onLoad"
		>
		<div class="cont-box">
		  <product3
			v-for="(item, idx) in goodsList"
			:key="item.id"
			:goods="item"
			:showAddbtn="true"
			@addCart="addCart"
			@reduceToCart="reduceToCart"
			:number.sync="item.goods_number"
			@click.native="gotoProductDetail(item.id)"
		  ></product3>
		</div>
		</van-list>
	</div>
	
	<product-sku-popup
		:skuPopShow="showSkuPop"
		skuPopTitle="选择商品规格"
		:skuPopGoods="skuPopGoodsInfo"
		@updateSelectedSku="updateSelectedSku"
		@addToCartLocal="addToCartLocal"
		@closeSkuPop="closeSkuPop"
		@quickToBuy="quickToBuy">
	</product-sku-popup>
	
	<van-tabbar route active-color="#FF8A00">
	  <van-tabbar-item replace to="/" icon="home-o">首页</van-tabbar-item>
	  <van-tabbar-item replace to="/products" icon="search">商品</van-tabbar-item>
	  <van-tabbar-item replace to="/cart" icon="cart-o">购物车</van-tabbar-item>
	  <van-tabbar-item replace to="/myinfo" icon="manager-o">我的</van-tabbar-item>
	</van-tabbar>
  </div>
</template>

<script>
  import mixin from 'utils/mixin'
  import product3 from 'components/product3'
  import productSkuPopup from 'components/product-sku-popup'

  export default {
    name: 'products',
    mixins: [mixin],
    data() {
      return {
        searchValue: '',
        tab: 0,
        page:0,
        totalPage:0,
        totalNumber:0,
        filters:{},  //商品筛选条件
        goodsList:[], //商品数据
        currentTheme:[], //主题数据
        isOffenHidden: true, // 常用搜索蒙层隐藏
        showSkuPop:false,
        skuPopGoodsInfo:{},
        selectedSkuId:'',
        selectedSkuName:'请选择',
        selectedSkuNumber:'1',
        newSort:'0',
        priceSort:'normal', //normal desc asc
        saleSort:'normal', //normal desc asc
		showBottomBar:false,
		loading: false,
		finished: false,
      }
    },
    components: {
      product3,
	  productSkuPopup,
    },
    created() {
      let theme_id = this.$route.query.tid;
      if(typeof(theme_id)=='undefined'){ theme_id = 0; }
      this.getTourGoodsList(1, 1,theme_id);
    },
    methods: {
      focusSearch() {
        this.isOffenHidden = false
      },
      hideOffenSearch() {
        this.isOffenHidden = true
      },
      searchGoods(e){
        let filters = this.filters;
        filters.keywords = this.searchValue;

        this.filters = filters
		
        this.isOffenHidden = true

        this.getTourGoodsList(1,true);
      },
      selHotWord(e){
        let word = e.currentTarget.dataset.word;
        let filters = this.filters;
        filters.keywords = word;

        this.filters = filters
        this.searchValue = word
        this.isOffenHidden =  true

        this.getTourGoodsList(1,true);
      },
      gotoProductDetail(tid){
        this.$router.push({
          name: 'tourDetail',
          query: {
            tid,
          }
        })
      },
	  onLoad(){
		 console.log("触底下拉");
		 console.log(this.page);
		 console.log(this.totalPage);
		if (this.page < this.totalPage) {
		    this.getTourGoodsList(parseInt(this.page) + 1,false)
		}else if(this.page>=this.totalPage){
			if(this.totalPage>0){
				this.$toast("这就是全部")
			}
			this.loading=false;
		}
	  },
	  closeSkuPop(val){
	  	this.showSkuPop=val
	  	this.skuPopTitle="选择商品规格"
	  }
	  
    }
  };
</script>

<style lang="scss" scoped>
  /* pages/products/products.wxss */
  .van-search {
    position: relative;
    z-index: 100;
    padding-top: 10px!important;
    width: 100%;
    background-color: transparent!important;
  }
  .van-search .van-cell {
    padding: 10px;
  }
  .van-search__content {
    border-radius: 44px!important;
  }
  .page {
    min-height: 100%;
    background-color: #efefef;
  }
  .nav {
    padding-top: 30px;
    padding-bottom: 60px;
  }

  .navbg {
    z-index: 10;
    left: 0;
    top: 0;
  }
  .theme-nav {
      width: 100%;
      padding-top: 112px;
	  padding-bottom:0px;
	  
  }
  .offen-search-top {
	top: 0;
	width:100%;
	height: 110px;
	position:fixed;
	z-index: 11;
	background-color:#FFFFFF;
  }
  .offen-search {
    position: fixed;
    z-index: 1000;
    left: 0;
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    padding: 0 30px;
    background-color: #FFFFFF;
  }
  .offen-search .title {
    margin: 20px;
    font-size: 32px;
    font-weight: bold;
    color: #191919;
  }
  .offen-search .list {
    display: flex;
    flex-wrap: wrap;
  }
  .offen-search .list .item {
    margin-bottom: 20px;
    margin-right: 20px;
    padding: 0 24px;
    height: 50px;
    line-height: 50px;
    border-radius: 25px;
    background-color: #F5F5F5;
    text-align: center;
    font-size: 24px;
    color: #191919;
  }
  .theme-page {
	  padding-bottom: 200px;
  }
  .tab-box {
    justify-content: space-around;
    margin: 0 30px;
    height: 87px;
    font-size: 28px;
    color: #191919;
    border-bottom: 1px solid #EEEEEE;
    border-radius: 12px 12px 0 0;
    background-color: #FFFFFF;
  }
  .tab-box .item {
    width: 120px;
    text-align: center;
  }
  .tab-box .active {
    font-weight: bold;
  }
  .tab-box .item .iconfont{
    font-size: 40px;
  }

  .cont-box {
    margin: 20px;
    padding: 20px 0px;
	min-height: 400px;
  }

  .no-theme-page {
	padding-top: 100px;
  }
  
  .bottom {
      justify-content: space-between;
      position: fixed;
      z-index: 1000;
      left: 0;
      right: 0;
      bottom: 96px;
	  bottom: calc(96px + constant(safe-area-inset-bottom));//兼容 IOS<11.2
	  bottom: calc(96px + env(safe-area-inset-bottom));//兼容 IOS>11.2
      padding: 0 30px;
      height: 110px;
	  box-sizing: border-box;
      border-top:2px solid #eeeeee;
      background-color:#FFFFFF;
	  background: #FFFFFF;
	  border-bottom:1px solid #eee;
  }
  .bottom .yixuan {
      width: 210px;
      height: 52px;
      line-height: 52px;
      border-radius: 32px;
      color: #191919;
      font-size: 24px;
  }
  .bottom .yixuan .iconfont {
      margin-right: 20px;
  }
  .bottom .heji {
      font-size: 24px;
      color: #191919;
	  width:60px;
  }
  .bottom .price {
      margin-right: 40px;
      font-size: 30px;
      color: #F84F41;
  }
  .bottom .hyf {
      margin-right: 40px;
      font-size: 24px;
      color: #777777;
      text-align: right;
  }
  .bottom .buy {
      width: 192px;
      height: 72px;
      line-height: 72px;
      border-radius: 36px;
      background-color: #FCC601;
      text-align: center;
      font-size: 30px;
      color: #FFFFFF;
  }
  .tab-box .desc {
      color: #FF0000;
      font-weight: bold;
  }
  .iconxiangshang,.iconxiangxia {
	  color: #FF0000;
	  font-weight: bold;
  }
  
</style>

