<template>
  <!--竖直排列-->
  <div class="detail-box">
    <div class="product_img">
		<img class="full-img up-img" :src="goods.default_image">
	</div>
    <div class="down">
      <div class="title-box">
        <div class="title1">{{goods.goods_name}}</div>
        <div class="title2">{{goods.goods_sn}}</div>
      </div>
      <div class="price-box flexcenter">
        <div class="price">￥{{goods.marking_price}}</div>
        <div class="huiyuan">
          <span class="hy1">￥{{goods.price}}</span>
          <span class="hy2">会员价</span>
        </div>
      </div>
      
      <div class="desc-box flexcenter">
        <div>已售{{goods.v_sale_number}}件</div>
        <i class="iconfont iconjia2"></i>
      </div>
    
    </div>
  </div>
</template>

<script>
  export default {
	name: 'product3',
	props: {
	  goods:Object,
	  showAddbtn:Boolean,
	  isShowChose: {
		type: Boolean,
		default: false,
	  },
	  bordernone: {
		type: Boolean,
	  }
	},
	data() {
	  return {
		value: 1,
	  }
	},
    methods: {
      onChange(event) {
        console.log(event.detail);
      },
    }
  }
</script>

<style scoped>
  .full-img {
    display: block;
    width: 100%;
  }
  .flexcenter {
    display: flex;
    align-items: center;
  }
  
  .detail-box {
	padding:20px 20px;
    margin-bottom: 24px;
    border-radius: 6px;
    background-color: #FFFFFF;
  }
  .detail-box .product_img {
	  height: 400px;
  }
  .detail-box .product_img img {
	  height: 100%;
  }
  .up-img {
    border-radius: 6px;
  }
  .down {
    padding: 12px;
  }
  .down .title-box {
    font-size: 28px;
    font-weight: bold;
    color: #191919;
  }
  .down .title-box .title1 {
  }
  .down .title-box .title2 {
    margin: 12px 0;
    font-size: 24px;
    font-weight: normal;
    color: #777777;
  }
  .down .price-box .price {
    margin-right: 6px;
    font-size: 30px;
    font-weight: bold;
    color: #F84F41;
    text-indent: -4px;
  }
  .down .price-box .huiyuan {
    text-align: center;
    font-size: 24px;
  }
  .down .price-box .huiyuan .hy1 {
    display: inline-block;
    width: 92px;
    height: 28px;
    line-height: 28px;
    background-image: linear-gradient(106deg, #464E51 0%, #454D51 100%);
    border-radius: 4px 0 0 4px;
    color: #E4DCAD;
  }
  .down .price-box .huiyuan .hy2 {
    display: inline-block;
    width: 92px;
    height: 28px;
    line-height: 28px;
    background-image: linear-gradient(48deg, #FCE7BD 0%, #FFF6D4 100%);
    border-radius: 0 4px 4px 0;
    color: #4C462C;
  }
  .down .desc-box {
    justify-content: space-between;
    font-size: 24px;
    margin: 16px 0;
    color: #777777;
  }
</style>
